<template>
  <img ref="reference" :src="link" class="gallery-image bg-gray-200" />
</template>

<script>
import { GetFile } from "@/assets/js/file-image.service"; // image service

   const mimes = {
        'jpeg': 'data:image/jpeg;base64,'
    };

export default {
  name: "evidenceimage",
  props: {
    url: String,
    reference: String,
  },
  computed: {},
  data: function () {
    return {
      link: "/static/images/bg.jpg",
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      var vm = this
   GetFile.from(this.url, mimes.jpeg)
   .then(function(data) {
           vm.link = data;

      // setTimeout(() => {
      //   GetFile.from(this.url)
      //     .then((f) => {
      //       this.link = f;
      //     })
      //     .catch((err) => {
      //       console.log(err);
           });
      // }, 1000);
    },
    checkImage(ref) {
      // console.log(this.$refs.reference.$el)
      // EXIF.getData(this.$refs.reference.$el, function () {
      //     alert(this.exifdata.Orientation);
      // });
    },
  },
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(6px);
  transition: filter 0.5s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.gallery-image {
  width: 100%;
  height: 100%;
  min-height: 120px !important;
  /* max-height: 180px !important; */
  object-fit: cover;
  image-orientation: from-image !important;
}
</style>
